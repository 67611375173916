@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');  /* font-family: 'Poppins', sans-serif; */

@font-face {
    font-family: 'heaters';
    src: url('../public/fonts/Heaters.woff2') format("woff2");   /*font-family: 'heaters', sans-serif;*/
}

@font-face {
    font-family: 'GothamBook';
    src: url('../public/fonts/GothamBook.woff2') format("woff2");   /*font-family: 'GothamBook', sans-serif;*/
}

@font-face {
    font-family: 'GothamBold';
    src: url('../public/fonts/GothamBold.woff2') format("woff2");   /*font-family: 'GothamBold', sans-serif;*/
}

:root {
    --color-web-1: #4a5266;
    --color-web-2: #7a8dc2;
    --color-web-3: #8a94b4;
    --color-web-4: #C3D1E4;
    --color-borders-1: #7a8dc285;
    --color-borders-2: #D0EDF4;
    --color-borders-3: #B5B3E8;
    --color-borders-4: #eee787;

    --color-sweetalert2: #7580a5;
    
    --font-style-principal: 'GothamBook', sans-serif;

    --font-style-1: 'heaters', sans-serif;
    --font1-small-size: 2.5rem;
    --font1-medium-size: 3.5rem;
    --font1-medium2-size: 6rem;
    --font1-large-size: 6.5rem;
        
    --font-style-2: 'GothamBold', sans-serif;
    --font2-default-size: 3rem;

    --font-style-3: 'GothamBook', sans-serif;
    --font3-default-size: 3rem;

    --text-light-color: rgb(255, 255, 255);
    --text-dark-color: rgb(31, 31, 31);

    --navBar-height: 6rem;
    --navBar-border-lines-color: #3f4555;
    --color-navbar-hover: var(--color-web-4);
    --alto-navbar: 4rem;
    --color-navbar: #242831d8;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.1;
    font-weight: 300;
    list-style: none;
    font-size: 1.35rem;
    font-family: var(--font-style-principal);
    color: var(--text-dark-color);
    text-align: center;
}

body {
    position: relative;
}

a {
    text-decoration: none;
    color: inherit;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column {
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.opb {
    object-position: bottom;
}

.opt {
    object-position: top;
}

.opc {
    object-position: center;
}

.opr {
    object-position: right;
}

.opl {
    object-position: left;
}

.mb1 {
    margin-bottom: 1rem;
}

.mb2 {
    margin-bottom: 2rem;
}

@media only screen and (orientation: landscape) {
    html {
        font-size: calc(1vh + 14px);
    }
}

@media only screen and (orientation: portrait) {
    html {
        font-size: calc(1vw + 14px);
    }
}

@media only screen and (orientation: portrait) {
    :root {
        --font1-large-size: 5.5rem;
    }
}