.contMenu {
    position: fixed;
    width: 100vw;
    height: var(--alto-navbar);
    top: 0;
    left: 0;
    z-index: 1000;
    padding-right: 1rem;
    border-bottom: 1px dashed var(--border-with-bg-dark);
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    background-color: var(--color-navbar);
}

.tapaMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 250;
    pointer-events: none;
}

.menu {
    width: 100%;
    height: 100%;
    z-index: 260;
}

.opcion {
    font-size: 0.85rem;
    height: 100%;
    padding: 0 1.5rem;
    color: var(--text-light-color);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
}

.opcion:hover {
    color: var(--color-navbar-hover);
}

.navBarContLogoEidioma {
    position: absolute;
    height: 100%;
    left: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 270;
}

.navBarContLogo {
    height: 100%;
    aspect-ratio: 1/1;
    justify-content: flex-start;
}

.navBarLogo {
    height: 80%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.iconoMenu {
    position: absolute;
    height: 50%;
    right: 0.75rem;
    top: 0;
    bottom: 0;
    margin: auto;
    filter: invert(100%);
    display: none;
    cursor: pointer;
    z-index: 270;
}

.navBarOptionColorOn {
    color: var(--color-navbar-hover)
}

@media only screen and (orientation: portrait) {

    .navBarContLogoEidioma {
        left: 0.75rem;
    }
    
    .navBarLogo {
        height: 70%;
    }

    .contMenu {
        border-bottom: 1px solid var(--border-with-bg-dark);
    }
}


/******************************** Clases que se cargan dinamicamente desde el javascript del navbar al superar o bajar de ciertos REM ******************************************/

.iconoMenuON {
    display: flex;
}

.menuOFF {
    display: none;
}

.menuBaja {
    display: flex;
    position: fixed;
    width: 18rem;
    top: var(--alto-navbar);
    right: 0;
    flex-direction: column;
    z-index: 190;
    height: fit-content;
    background-color: var(--color-navbar);
    animation-name: menuBaja;
    animation-duration: 0.35s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    border-left: 1px dotted var(--border-with-bg-dark);
    border-bottom: 1px dotted var(--border-with-bg-dark);
}

@keyframes menuBaja {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.menuSube {
    display: flex;
    position: fixed;
    width: 18rem;
    top: var(--alto-navbar);
    right: 0;
    flex-direction: column;
    z-index: 190;
    height: fit-content;
    background-color: var(--color-navbar);
    animation-name: menuSube;
    animation-duration: 0.35s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    border-left: 1px dotted var(--border-with-bg-dark);
    border-bottom: 1px dotted var(--border-with-bg-dark);
}

@keyframes menuSube {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(100% + 1px));   /* 1px es para que no se vea el borde izquierdo al cerrar el menu */
    }
}

.opcion2 {
    padding: 0.75rem 0;
    padding-left: 2rem;
    width: 100%;
    font-weight: 400;
    justify-content: flex-start;
    font-size: 0.8rem;
    text-align: left;
}

.opcion2:hover {
    color: var(--color-navbar-hover);
    animation-name: rightMove;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes rightMove {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(1.5rem);
    }
}


