.cursive {
    font-style: oblique;
}

.textBold {
    font-size: inherit;
    font-family: var(--font-style-2);
}

.seccionsPadding {
    padding: 3rem 1rem;
}

.displayNone {
    display: none;
}

.iconsTextBold {
    font-family: 'GothamBold', sans-serif;
    font-size: 2.5rem;
}

.invert100 {
    filter: invert(100%);
}


/*************************************************************************/

.seccionToWaitImages {
    opacity: 0;
}

.opacityOnCharge {
    animation-name: opacityOnCharge;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0;
}

@keyframes opacityOnCharge {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.opacityOnCharge1s5 {
    animation-duration: 1.5s;
}

.opacityOnCharge0s5 {
    animation-duration: 0.5s;
}

/********************************** OoS ********************************/

.OoS {
    opacity: 0;
}

.OoSS {
    animation-name: animationOfScroll; 
    animation-duration: 0.75s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes animationOfScroll {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/***********************************************************************/

.fadeInIzq {
    animation-name: fadeInIzq; 
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes fadeInIzq {
    0% {
        opacity: 0;
        transform: translateX(-4rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


/******************************* Sweet Alert **************************/

.sweetTitleWithOptions {
    font-size: 1.5rem;
}

.swal2-container {
    z-index: 9500 !important;               /* Para que el spinner no tape el popup */    
}

.swal2-icon {
    border-color: var(--color-sweetalert2) !important;
    transform: scale(65%) !important;
}

.swal2-x-mark {
    width: 100%;
    height: 100%;
}

.swal2-icon-content {                       /* Para que el texto dentro del icono redondo sea del mismo color que el circulo del icono */
    color: var(--color-sweetalert2); 
    font-size: 5rem !important;
}

.swal2-shown {                              /* Para que no desaparezca el scroll cuando aparace el popup de sweetalert2"*/
    overflow: auto;
}

.swal2-title {
    font-size: 1.25rem;                     /* Titulo */
}

.swal2-html-container {                     /* Subtitulo */
    font-size: 1rem !important;
}

.swal2-popup {                              /* Contenedor */
    width: 25rem;
    max-width: 100%;
    z-index: 2500;
}

.swal2-confirm {
    background-color: var(--color-sweetalert2) !important;
}

.swal2-confirm:focus {
    outline: none !important;
}

.swal2-confirm:hover {
    background-color: var(--color-web-3) !important;
}

.swal2-height-auto {
    overflow:  hidden !important;
}