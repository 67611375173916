.wsfCont {
    position: fixed;
    right: 0.5rem;
    bottom: 0.5rem;
    height: 3.5rem;
    width: 3.5rem;
    z-index: 200;
}

.wsf {
    height: 100%;
    width: 100%;
}