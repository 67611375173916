.homeMainCont{
    height: 100vh;
    width: 100%;
    position: relative;
}

.homeMainLogo {
    width: 10rem;
    height: 7rem;
    object-fit: contain;
    max-width: 33vw;
}

.homeMainBgImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    z-index: -1;
}

.homeMainText1 {
    line-height: 0.90;
    color: var(--text-light-color);
    font-size: 3.5rem;
    font-family: var(--font-style-2);
}

.homeMainText2 {
    color: var(--text-light-color);
    font-size: var(--font1-small-size);
    font-family: var(--font-style-1);
}

.homeMainDivider {
    width: 0.275rem;
    height: 2.75rem;
    background-color: white;
    margin: 0 0.80rem;
}

@media only screen and (orientation: portrait) {

    .homeMainText1 {
        font-size: 2.5rem;
    }
}