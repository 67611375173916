.childsCont {
    padding-top: 0;
}

.childsDescriptionCont {
    border: 3px solid var(--color-borders-2);
}

.coursesDescriptionBackDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: auto;
    width: 75%;
    background-color: var(--color-borders-2);
    height: 2.5rem;
}

.activitiesOptionContPagesHead {
    margin-bottom: 2rem;
    width: max-content;
}