.galleryCont {
    position: relative;
    --alto-thumbnails: 5rem;
    width: 100%;    
    height: 100vh;
    justify-content: flex-start;
    z-index: 2500;
}

.gallerySliderMainCont {
    position: relative;
    height: calc(100% - var(--alto-thumbnails));
    width: 100%;
    overflow: hidden;
}

.gallerySliderMainContMaximixed img:first-child {
    display: flex;
}    

.gallerySliderMainContMaximixed {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2500;
    background-color: black;
}

.gallerySliderCont {
    width: 100%;
    height: 100%;
}

.galleryImg {
    height: 100%;
    object-fit: cover;
    padding: 0 0.25rem;
    cursor: pointer;
}

.galleryImgContain {
    object-fit: contain;
}

.galleryImgMaximixed {
    width: 100%;
    min-width: 100%;
    object-fit: contain;
    padding: 0;
    pointer-events: none;
}

.galleryThumbnailsCont {
    height: var(--alto-thumbnails);
    width: 100%;
    padding: 0.25rem 0;
    overflow: auto;
}

.galleryThumbnailImg {
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    z-index: 200;
    cursor: pointer;
    margin: 0 0.125rem;
}

.galleryThumbnailImgSelect {
    border: 4px solid var(--color-web-2);
    z-index: 1000;
}

.galleryNextIcon, .galleryPrevIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    filter: invert(90%);
    height: 4rem;
    cursor: pointer;
    z-index: 2000;
}

.galleryPrevIcon {
    left: 0.5rem;
    transform: rotateY(180deg);
}

.galleryNextIcon {
    right: 0.5rem;
}

.galleryNext {
    animation-name: galleryNext;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.galleryCloseIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1.5rem;
    filter: invert(100%);
    cursor: pointer;
    z-index: 2500;
    display: none;
}

.galleryBulletsCont {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2500;
}

.galleryBullet {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    border: 0.1rem solid white;
    margin: 0.25rem;
    cursor: pointer;
}

.galleryBulletSelect {
    background-color: white;
}

@keyframes galleryNext {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@media only screen and (orientation: portrait) {
    .galleryImg {
        width: 100%;
        min-width: 100%;
        object-fit: cover;
        padding: 0;
    }
 
    .galleryImgMaximixed {
        object-fit: contain;
    }

    .galleryThumbnailsCont {
        display: none;
    }

    .gallerySliderMainCont {
        height: 100%;
    }

}

