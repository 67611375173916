.coursesCalendarCont {
    margin-bottom: 4rem;
}

.coursesCalendarIconCont {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 0 0.5rem;
}

.coursesCalendarIconFirst {
    filter: brightness(0) invert(0.75) sepia(1) saturate(5) hue-rotate(180deg);
}

.coursesCalendarIcon {
    width: 100%;
    height: 100%;
}

.coursesCalendarText {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;
    padding-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.coursesDescriptionCont {
    position: relative;
    border: 3px solid var(--color-borders-1);
    padding: 3rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    width: 80%;
    max-width: 80%;
}

.coursesDescriptionMainTextCont {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 100%;
    transform: translateY(50%);
}

.coursesDescriptionMainText {
    background-color: white;
    font-family: var(--font-style-1);
    font-size: var( --font1-large-size);
    color: var(--color-web-1);
    line-height: 0.5;
}

.coursesDescriptionTitle {
    margin-top: 3.5rem;
    margin-bottom: 1rem;
    font-family: var(--font-style-2);
}

.coursesDescriptionTitleFirst {
    margin-top: 0;
}

.coursesDescriptionText {
    margin-bottom: 0.25rem;
}

.coursesDescriptionTextNumber {
    opacity: 0.50;
}

.coursesDescriptionTextCursive {
    font-style: oblique;
    opacity: 0.85;
}

.coursesDescriptionBackTextCont {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    transform: translateY(-50%);
}

.coursesDescriptionBackText {
    padding: 0.5rem 4rem;
    font-family: var(--font-style-2);
    background-color: var(--color-web-4);
    box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.75);
    color: var(--color-web-1);
}

.coursesModuleCont {
    position: relative;
    border: 3px solid var(--color-borders-1);
    padding: 0.5rem 2rem;
    padding-left: 0;
    justify-content: space-between;
    border-left: none;
    margin-bottom: 2rem;
}

.coursesModuleDivDer {
    align-items: flex-end;
}

.coursesModuleDivDerText {
    font-family: var(--font-style-2);
}

.coursesModuleDivIzq {
    transform: translateX(-50%);
}

.coursesModuleText1 {
    font-family: var(--font-style-1);
    font-size: var(--font1-medium2-size);
    color: var(--color-web-1);
    line-height: 0.75;
}

.coursesModuleText2 {
    font-family: var(--font-style-2);
    color: var(--color-web-1);
}

.coursesModuleDay {
    position: absolute;
    font-size: 1.6rem;
    font-family: var(--font-style-2);
    color: var(--color-borders-1);
    transform: rotateZ(90deg) translateY(-0.9rem) translateX(1.05rem); 
    transform-origin: right;
    right: 0;
    bottom: 0;
}

.coursesModuleCon2 {
    transform: rotateY(180deg);
}

.coursesModuleCon2 p:not(.coursesModuleDay2) {
    transform: rotateY(180deg);
}

.coursesModuleDay2 {
    transform: rotateZ(90deg) translateY(-0.9rem) translateX(-87%) rotateY(180deg); 
}

.coursesTextDivider {
    font-family: var(--font-style-2);
    text-transform: uppercase;
    margin: 2rem 0;
    color: var(--color-web-1);
}

.coursesDescriptionBackTextContDBlock {
    position: initial;
    left: initial;
    right: initial;
    top: initial;
    transform: initial;
    margin-top: 0.5rem;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: transform;
}

.coursesDescriptionBackTextContDBlock:hover {
    transform: scale(105%);
}

@media only screen and (orientation: portrait),
       only screen and (orientation: landscape) and (max-width: 1000px) {

    .coursesModuleCont {
        flex-direction: column;
    }

    .coursesModuleText1 {
        font-size: 4rem;
    }

    .coursesModuleText2 {
        font-size: 1.5rem;
    }

    .coursesDescriptionCont {
        padding: 3rem 1rem;
        padding-top: 5rem;
        width: 100%;
        max-width: 100%;
    }

    .coursesCalendarIconCont {
        width: 4rem;
        height: 4rem;
        margin: 0 0.25rem;
    }
    
    .coursesDescriptionMainText {
        min-width: 95%;
        width: min-content;
        line-height: 0.60;
    }

    .coursesDescriptionMainTextShort {
        padding: 0 1rem;
        min-width: min-content;
        width: min-content;
    }

    .coursesDescriptionBackText {
        padding: 0.5rem 1rem;
    }

    .coursesModuleDivIzq {
        margin-bottom: 1rem;
    }

    .coursesModuleCont {
        padding: 0.5rem 1rem;
    }

    .coursesTextDivider {
        font-size: 1.5rem;
    }

    .coursesModuleDivDerText {
        font-size: 1.35rem;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1000px) {

    .coursesModuleText1 {
        font-size: 5rem;
    }

    .coursesDescriptionMainText {
        min-width: 80%;
        width: min-content;
        line-height: 0.60;
    }

    .coursesDescriptionMainTextShort {
        min-width: min-content;
        width: min-content;
    }

    .coursesModuleCont {
        flex-direction: row;
    }
}

