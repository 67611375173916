.activitiesCont {
    min-height: 100vh;
    width: 100%;
    background-color: white;
    position: relative;
    padding-bottom: 2rem;
}

.activitiesTitleCont {
    align-items: flex-start;
    z-index: 10;
}

.activitiesTitle1 {
    font-family: var(--font-style-2);
    font-size: var(--font2-default-size);
    color: var(--color-web-2);
    line-height: 0.75;
}

.activitiesTitle2 {
    font-family: var(--font-style-1);
    font-size: var(--font1-large-size);
    color: var(--color-web-1);
    line-height: 0.75;
}

.activitiesDescCont {
    border: 2px solid var(--text-dark-color);
    padding: 1rem;
    max-width: 100%;
}

.activitiesDesc2 {
    font-family: var(--font-style-1);
    font-size: var(--font1-medium-size);
    color: var(--color-web-3);
    margin-top: 1rem;
    line-height: 0.85;
}

.activitiesOptionsCont {
    margin-bottom: 4rem;
}

.activitiesOptionCont {
    width: 12rem;
    max-width: 100%;
    margin: 1rem;
    padding: 1rem;
}

.activitiesOptionContHover {
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: transform;
}

.activitiesOptionContHover:hover {
    transform: scale(110%);
}

.activitiesOptionsIcon {
    width: 10rem;
    height: 10rem;
}

.activitiesOptionText {
    text-align: left;
}

.activitiesMountainImgCont {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20rem;
    align-items: flex-end;
}

.activitiesMountainImg {
    width: 100%;
}

@media only screen and (orientation: portrait) {

    .activitiesMountainImg {
        opacity: 0.5;
    }

    .activitiesOptionCont {
        margin: 0;
    }
    
}
